import React from "react";

import { blue, red, pink } from "@material-ui/core/colors";

//Language
import Language from "./Language";

//Router
import { Link } from "react-router-dom";

//Images
import logoMob from "../../images/AforehandStudio_logo_v2.webp";
import gplayBig from "../../images/GooglePlay.png";

//Icons
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";

//Styles
import "./Footer.css";
const Footer = ({ stateLanguage }) => {

  const [languages] = Language;
  const normalVers = <React.Fragment>
    <div className="row" style={{marginBottom:"0.5em"}}>
      <div className="col">
        <Link to="/main" className="footer-logo" onClick={()=>{ window.scrollTo(0,0)}}>
          <img src={logoMob} alt="Logo"></img>
        </Link>
      </div>

      <div className="col-6" style={{ marginTop: "0.7em" }}>
        <div className="footer-right">
          <div className="row">
            <div className="col" style={{ textAlign: "left", marginLeft: "5%" }}>
              <Link to="/contact" style={{ color: "purple" }}
              data-content={stateLanguage && languages[stateLanguage].link3}>
                {stateLanguage && languages[stateLanguage].link3}
              </Link>
            </div>
            <div className="col" style={{ textAlign: "left" }}>
              <Link to="/orderApp"
                data-content={stateLanguage && languages[stateLanguage].link4} style={{ color: "purple" }}>
                {stateLanguage && languages[stateLanguage].link4}
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-6" style={{ textAlign: "left", marginLeft: "5%" }}>
              <Link
                to="/privacy-police"
                data-content={stateLanguage && languages[stateLanguage].link5} style={{ color: "purple" }}
              >
                {stateLanguage && languages[stateLanguage].link5}
              </Link>
            </div>

            <div className="col-6" style={{ textAlign: "left" }}>
            </div>
          </div>
          <div className="row">
            <div className="col-6" style={{ textAlign: "left", marginLeft: "5%" }}>
            <Link
                to="/user-argeement"
                data-content={stateLanguage && languages[stateLanguage].link6} style={{ color: "purple" }}>
                {stateLanguage && languages[stateLanguage].link6}
              </Link>
            </div>

            <div className="col-6" >
            </div>
          </div>
        </div>
      </div>
      <div className="col social-button" >
        <div className=" row icons" >
          <a href="https://www.youtube.com/channel/UCR2iEIw3odMpEz7zYPCOUqg" className="social-link" target="_blank" >
            <YouTubeIcon style={{ color: red[700],width: '300%' }}> </YouTubeIcon>
          </a>

          {/* <a href="https://instagram.com/koza_style_official?utm_medium=copy_link" className="social-link" target="_blank">
            <InstagramIcon style={{ color: pink[400],width: '300%'  }}> </InstagramIcon>
          </a> */}
          <div className="madeIn">© 2022, AFOREHAND Studio</div>
        </div>
      </div>
    </div>
  </React.Fragment>
  const mobVers = <React.Fragment>
    <div className="row">
      <div className="col">
        <Link to="/main" className="footer-logo"  onClick={()=>{ window.scrollTo(0,0)}}>
          <img src={logoMob} alt="Logo"></img>
        </Link>
      </div>


      <div className="col social-button">
        <div className=" row icons">
          <a href="https://www.youtube.com/channel/UCR2iEIw3odMpEz7zYPCOUqg" className="social-link" target="_blank" >
            <YouTubeIcon style={{ color: red[700],width:'300%' }}> </YouTubeIcon>
          </a>

          {/* <a href="#" className="social-link" target="_blank">
            <InstagramIcon style={{ color: pink[400] }}> </InstagramIcon>
          </a> */}
        </div>
      </div>
    </div>
    <div className="col" style={{ marginTop: "0.7em" }}>
      <div className="footer-right">
        <div className="row">
          <div className="col" style={{ textAlign: "left", marginLeft: "5%" }}>
            <Link to="/contact" 
              data-content={stateLanguage && languages[stateLanguage].link3} style={{ color: "purple" }}>
              {stateLanguage && languages[stateLanguage].link3}
            </Link>
          </div>
          <div className="col" style={{ textAlign: "left" }}>
            <Link to="/orderApp"
              data-content={stateLanguage && languages[stateLanguage].link4} style={{ color: "purple" }}>
              {stateLanguage && languages[stateLanguage].link4}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ textAlign: "left", marginLeft: "5%" }}>
            <Link
              to="/privacy-police"
              data-content={stateLanguage && languages[stateLanguage].link5} style={{ color: "purple" }}
            >
              {stateLanguage && languages[stateLanguage].link5}
            </Link>
          </div>

          <div className="col" style={{ textAlign: "left" }}>
            <Link
              to="/user-argeement"
              data-content={stateLanguage && languages[stateLanguage].link6} style={{ color: "purple" }}>
              {stateLanguage && languages[stateLanguage].link6}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>

  return (
    <footer className="footer-body">
      <div className="container footer-content">
        {window.innerWidth <= 479 ?  mobVers : normalVers}
      </div>
    </footer >
  );
};

export default Footer;
