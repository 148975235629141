import React, { useEffect, useState } from "react";
import hb1 from "../../images/hb_v1.png";
import mishka1 from "../../images/mishka_v1.png";
import marmelad1 from "../../images/marmelad_v1.png";
import sakura1 from "../../images/sakura_v1.png";
import googleplay from "../../images/GooglePlay.png";
import { connect } from "react-redux";

//Components
import ModalDefault from "../../Components/ModalDefault/ModalDefault";

//Language
import Language from "./Language";

//router
import { useLocation } from "react-router-dom";

import { changeStateModalBuy, changeStateModalUse } from "../../Redux/Actions/ProductsActions";

//Buttons
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { orange } from "@material-ui/core/colors";

//style
import "./CardOfProduct.css";

export default class CardOfProduct extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            VideoSize: {
                width: 0,
                height: 0
            },
            ScreenSize: {
                width: 0,
                height: 0
            },
            stateLanguage: props.stateLanguage
        }
    }

    theme = createMuiTheme({
        palette: {
            primary: orange,
        },
    });

    convertStrWidthToInt = (x) => {
        return parseFloat(x.split('px')[0]);
    };
    resetVideoSize = () => {
        var videoWidth = 0;
        var videoHeight = 0;
        if (!window.mobileCheck()) {
            //videoWidth=(document.getElementById("divCol10WithVideo") ? document.getElementById("divCol10WithVideo").clientWidth-document.getElementById("divCol10WithVideo").offsetLeft : 100);
            videoWidth = (document.getElementById("divCol10WithVideo") ? this.convertStrWidthToInt(getComputedStyle(document.getElementById("divCol10WithVideo")).width) : 10);
            if (this.state.ScreenSize.height <= 1080) {
                videoWidth *= (this.state.ScreenSize.height / 1080 * 0.9).toFixed(4);
            }
        }
        else {
            videoWidth = 10;
        }
        videoHeight = videoWidth * 16 / 9;
        var VideoSize = { width: videoWidth, height: videoHeight };
        this.setState({
            VideoSize,
            ScreenSize: {
                width: window.screen.availWidth,
                height: window.screen.availHeight
            }
        });
    };


    checkUpdates = () => {
        this.resetVideoSize();
    }




    componentDidUpdate() {
        if ((this.state.VideoSize.width == 10) && ((document.getElementById("divCol10WithVideo") ? this.convertStrWidthToInt(getComputedStyle(document.getElementById("divCol10WithVideo")).width) : false))) {
            this.checkUpdates();
        }
    }



    componentDidMount() {
        window.scrollTo(0, 0);

        this.setState({
            VideoSize: {
                width: 0,
                height: 0
            },
            ScreenSize: {
                width: window.screen.availWidth,
                height: window.screen.availHeight
            },
            stateLanguage: this.props.stateLanguage
        });

        document.addEventListener("DOMContentLoaded",
            () => {
                this.checkUpdates();
            }
        );
        this.checkUpdates();

    }



    render() {

        var { stateLanguage } = this.state;
        const [languages] = Language;
        const mobVers = <React.Fragment>
            <div className="col-12" style={{ margin: '60px auto 0' }}>
                <div className="row col-12">
                    <div className="card-product"></div>
                    <div className="product__banner video2">
                        <div className="product__banner-text">
                            <h4 style={{ textAlign: "center" }}>
                                {stateLanguage && languages[stateLanguage].description1}
                            </h4>

                            <p style={{ textAlign: "justify", paddingTop:'1em', paddingBottom:'1em', marginBottom:'0' }}>
                                {stateLanguage && languages[stateLanguage].description2}
                                <table>
                                    <tr>
                                        <td><a href="https://kharkov.prom.ua/p1452173617-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={hb1} alt="текст" /></a>
                                        </td>
                                        <td><a href="https://kharkov.prom.ua/p1452193872-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={mishka1} alt="текст" /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="https://kharkov.prom.ua/p1454466395-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={marmelad1} alt="текст" /></a>
                                        </td>
                                        <td><a href="https://kharkov.prom.ua/p1452196465-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={sakura1} alt="текст" /></a>
                                        </td>
                                    </tr>
                                </table>
                                {stateLanguage && languages[stateLanguage].description3}
                            </p>
                        </div>

                        <div className="col-12" >
                            <div className="row">
                                <div className="col-12" >
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/d_Epz7abhq8?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    {/* <iframe className="asdf" height={16 * window.screen.availWidth / 9 * 0.8} width={window.screen.availWidth * 0.8} src="https://www.youtube.com/embed/d_Epz7abhq8?loop=1&autoplay=1&playlist=d_Epz7abhq8" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen  ></iframe> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col"></div>
                                <div className="col-6" style={{ paddingTop: "1em", paddingBottom: "1em" }}>
                                    <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" style={{ textAlign: "center" }} ><img width="200em" src={googleplay} alt="Links to download" /></a>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>

                    </div>


                    <div  id="giftSet">
                        {stateLanguage && languages[stateLanguage].giftSet}
                    </div>

                    <div className="product__instruction">
                        <div style={{ fontSize: "1.25em", paddingBottom: "0.5em" }}>
                            {stateLanguage && languages[stateLanguage].instructions}
                        </div>
                        <ol className="instruction-list">
                            {stateLanguage && languages[stateLanguage].instructionList}
                        </ol>
                    </div>
                </div>
            </div>
        </React.Fragment>;

        const normalVers = <React.Fragment>
            <div className="col-11" style={{ margin: '0 auto' }}>
                <div className="row col-12">
                    <div className="card-product"></div>
                    <div className="product__banner video1">
                        <div className="product__banner-text">
                            <h2 style={{ textAlign: "center" }}>
                                {stateLanguage && languages[stateLanguage].description1}
                            </h2>

                            <p style={{ textAlign: "justify" }}>
                                {stateLanguage && languages[stateLanguage].description2}
                                <table>
                                    <tr>
                                        <td><a href="https://kharkov.prom.ua/p1452173617-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={hb1} alt="текст" /></a>
                                        </td>
                                        <td><a href="https://kharkov.prom.ua/p1452193872-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={mishka1} alt="текст" /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="https://kharkov.prom.ua/p1454466395-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={marmelad1} alt="текст" /></a>
                                        </td>
                                        <td><a href="https://kharkov.prom.ua/p1452196465-komplekt-bumazhnoj-posudy.html"><img width="100%" height="100%" src={sakura1} alt="текст" /></a></td>
                                    </tr>
                                </table>
                                {stateLanguage && languages[stateLanguage].description3}
                            </p>
                        </div>

                        <div className="col-6">
                            <div className="row">
                                <div className="col"></div>
                                <div className="col-10" id="divCol10WithVideo">
                                    <iframe className="video-css"
                                        width={this.state.VideoSize.width}
                                        height={this.state.VideoSize.height}
                                        src="https://www.youtube.com/embed/d_Epz7abhq8?loop=1&autoplay=1&playlist=d_Epz7abhq8" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{ marginLeft: "5em" }} ></iframe>
                                </div>
                                <div className="col"></div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col"></div>
                                <div className="col-4">
                                    <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" ><img width="200em" src={googleplay} alt="Links to download" /></a>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>

                    </div>


                    <div style={{ paddingTop: "1em" }} id="giftSet">
                        {stateLanguage && languages[stateLanguage].giftSet}
                    </div>

                    <div className="product__instruction">
                        <div style={{ fontSize: "1.25em", paddingBottom: "0.5em" }}>
                            {stateLanguage && languages[stateLanguage].instructions}
                        </div>
                        <ol className="instruction-list">
                            {stateLanguage && languages[stateLanguage].instructionList}
                        </ol>
                    </div>
                </div>
            </div>
        </React.Fragment>;

        return (
            <main className="main">
                <div className="container">

                    {this.state.ScreenSize.width <= 479 ? mobVers : normalVers}
                </div>

            </main>
        );
    };

};