import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import CardOfProduct from "../../Containers/CardOfProduct/CardOfProduct";
import BannerImages from "../../Containers/BannerImages/BannerImages.jsx";
import MainPage from "../../Containers/MainPage/MainPage";
import PrivacyPolicy from "../../Containers/PrivacyPolicy/PrivacyPolicy";
import Help from "../../Containers/Help/Help";
import ResetPass from "../../Containers/ResetPassword/ResetPass";


import UserArgeement from "../../Containers/UserArgeement/UserArgeement";
import Contact from "../../Containers/Contact/Contact";
import ArApp from "../../Containers/ArApp/ArApp";
import SP1 from '../../Containers/SecretPages/sp1';
import DeliveryForm from "../../Containers/deliveryForm/deliveryForm";
import YoReminder from "../../Containers/YoReminder/yoreminder";
import ARPuzzle from "../../Containers/ArPuzzles/arPuzzles";
import PageError from "../../Containers/PageError/PageError";
import ArPuzzleReset from "../../Containers/ArPuzzleReset/ArPuzzleReset";
import MatevosPrivacyPolicy from "../../Containers/PresentToMatevos/MatevosPrivacyPolicy";
import MatevosTermsConditions from "../../Containers/PresentToMatevos/MatevosTermsConditions";
import MatevosATermsConditions from "../../Containers/PresentToMatevos/MatevosATermsConditions";
const RootRouter = ({ stateLanguage, changeLanguage }) => {
    const isLanguageValid = (lang) => {
        return ((lang == "EN") || (lang == "ES") || (lang == "UA") || (lang == "DE")) ? true : false;
    }
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={{ pathname: "/main" }} />
            </Route>

            <Route exact path="/main/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <MainPage stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <MainPage stateLanguage={language.toUpperCase()} />
                        }
                        return <MainPage stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            <Route exact path="/ar-koza-party/ARimages/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <BannerImages stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <BannerImages stateLanguage={language.toUpperCase()} />
                        }
                        return <BannerImages stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            <Route exact path="/ar-koza-party/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <CardOfProduct stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <CardOfProduct stateLanguage={language.toUpperCase()} />
                        }
                        return <CardOfProduct stateLanguage={stateLanguage || "EN"} />;
                    }}
            />



            <Route path="/present-to-matevos/privacy-policy/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <MatevosPrivacyPolicy stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <MatevosPrivacyPolicy stateLanguage={language.toUpperCase()} />
                        }
                        return <MatevosPrivacyPolicy stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            <Route path="/present-to-matevos/terms-conditions/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <MatevosTermsConditions stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <MatevosTermsConditions stateLanguage={language.toUpperCase()} />
                        }
                        return <MatevosTermsConditions stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            <Route path="/present-to-matevos/a-terms-conditions/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <MatevosATermsConditions stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <MatevosATermsConditions stateLanguage={language.toUpperCase()} />
                        }
                        return <MatevosATermsConditions stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
             <Route path="/privacy-police/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <PrivacyPolicy stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <PrivacyPolicy stateLanguage={language.toUpperCase()} />
                        }
                        return <PrivacyPolicy stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            <Route path="/user-argeement/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <UserArgeement stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <UserArgeement stateLanguage={language.toUpperCase()} />
                        }
                        return <UserArgeement stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            <Route exact path="/contact/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <Contact stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <Contact stateLanguage={language.toUpperCase()} />
                        }
                        return <Contact stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            <Route exact path="/orderApp/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <ArApp stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <ArApp stateLanguage={language.toUpperCase()} />
                        }
                        return <ArApp stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            <Route exact path="/delivery-form/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <DeliveryForm stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <DeliveryForm stateLanguage={language.toUpperCase()} />
                        }
                        return <DeliveryForm stateLanguage={stateLanguage || "EN"} />;
                    }}
            />

            <Route exact path="/error/:language?"
                render={
                    ({ match }) => {
                        const { language } = match.params;
                        if (!language) {
                            //language="en";
                            return <PageError stateLanguage={stateLanguage || "EN"} />
                        }
                        if (isLanguageValid(language.toUpperCase())) {
                            changeLanguage(language.toUpperCase());
                            return <PageError stateLanguage={language.toUpperCase()} />
                        }
                        return <PageError stateLanguage={stateLanguage || "EN"} />;
                    }}
            />
            {/* NotSearcheablePages */}
            <Route path="/marmelad">
                <SP1 stateLanguage={stateLanguage} />
            </Route>
            <Route path="/YoReminder/ResetPassword">
                <ResetPass stateLanguage={stateLanguage} />
            </Route>

            <Route path="/YoReminder">
                <YoReminder stateLanguage={stateLanguage} />
            </Route>
            <Route path="/ARPuzzle/ResetPassword">
                <ArPuzzleReset stateLanguage={stateLanguage} />
            </Route>
            <Route path="/ARPuzzle">
                <ARPuzzle stateLanguage={stateLanguage} />
            </Route>

            {/* <Route  path="/ResetPassword">
                <ResetPass stateLanguage={stateLanguage}/>
            </Route> */}
            {/* <Route   path="/">
                <MainPage stateLanguage={stateLanguage}/> 
                <Redirect to="/card-of-product"/>
            </Route> */}

            <Route>
                <Redirect to={{ pathname: "/error" }} />
            </Route>
        </Switch>
    );
};

export default RootRouter;