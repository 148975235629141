const Language = [{
    EN: {
        pageNot: <>
            Page not found
        </>,
        check: <>
            Check if you entered the correct address.
        </>,
        redirect: <>
        </>,
        button: <>
              <button class="btn btn-outline-primary buttonStyle " >Go to home</button>
        </>
    },
    ES: {
        pageNot: <>
            Página no encontrada
        </>,
        check: <>
            Verifique si ingresó la dirección correcta.
        </>,
        redirect: <>
        
        </>,
        button: <>
              <button class="btn btn-outline-primary buttonStyle " >Ir a casa</button>
        </>
    },
    DE: {
        pageNot: <>
           Seite nicht gefunden
        </>,
        check: <>
            Überprüfen Sie, ob Sie die richtige Adresse eingegeben haben.
        </>,
        redirect: <>
       
        </>,
        button: <>
              <button class="btn btn-outline-primary buttonStyle " >Zur Startseite</button>
        </>
    },
    UA: {
        pageNot: <>
        Сторінку не знайдено
    </>,
    check: <>
       Перевірте, чи правильно ви ввели адресу.
    </>,
    redirect: <>
     
    </>,
    button: <>
          <button class="btn btn-outline-primary buttonStyle " >На головну</button>
    </>
    },
}]
export default Language;