const Language = [{
    EN: {
        description1: <>
            Add a cheerful mood <br />to a party with friends or family!
        </>,
        description2: <> All you have to do is download the < a target ="_blank" target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank">AR Koza Party app,</a> see pictures on paper cups, plates or holiday caps through the camera of your smartphone and the magic will begin...<br />
            For each of the four series of < a  style={{ color: "purple" }} href="/ar-koza-party/ARimages" >KOZA&#8209;STYLE paper products</a>:<br />
        </>,
        description3: <>
            the < a target ="_blank" style={{ color: "purple" }} target ="_blank" href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank">AR Koza Party app</a> has a separate augmented reality entertainment. Take pictures of magical interactive entertainment in augmented reality with friends or family and share it on social networks - it`s very simple!
        </>,
        giftSet: <>
            <p>
                To order AR-sets with delivery across Ukraine,  < a target ="_blank"  target ="_blank" href='https://prom.ua/c667862-koza-style.html?product_group=99301489' style={{ color: 'purple' }}>click here.</a><br /> To order AR-sets with delivery OUTSIDE Ukraine,  < a target ="_blank" href='/delivery-form'  style={{ color: 'purple' }}>click here</a>.
            </p>
        </>,
        instructions: <>
            Instructions for using the < a target ="_blank" target ="_blank" href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" style={{ color: "purple" }}> AR Koza Party app:</a>
        </>,
        instructionList: <>
            <li>Download the < a target ="_blank" target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY"> AR Koza Party app.</a></li>
            <li>The upper part of the screen shows the source objects (plates, cups, caps < a  style={{ color: "purple" }} href="/ar-koza-party/ARimages">KOZA‑STYLE</a>, that can be found in the retail chains Silpo, Класс, Рост), when you hover over which appear game scenes and mini-games augmented reality.</li>
            <li>For faster loading times of mini‑games and game scenes target models shouldn't have glare.</li>
            <li>In each game scene there are clickable items.</li>
            <li>To share an interesting screenshot with friends tap the camera button, then tap the share button.</li>
            <li>To close the scene, press the Back button.</li>
        </>
    },
    ES: {
        description1: <>
            ¡Agregue un ambiente alegre<br /> a una fiesta con amigos o familiares!
        </>,
        description2: <>Todo lo que tiene que hacer es descargar la < a target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank"> aplicación AR Koza Party</a>, ver imágenes en vasos de papel, platos o gorras navideñas a través de la cámara de su teléfono inteligente y comenzará la magia...<br />
            Para cada una de las cuatro series < a style={{ color: "purple" }} href="/ar-koza-party/ARimages" >de productos de papel KOZA&#8209;STYLE</a>:<br />
        </>,
        description3: <>
            < a target ="_blank" href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" style={{ color: "purple" }} target="_blank">AR Koza Party app</a> tiene un entretenimiento de realidad aumentada separado. Tome fotografías de entretenimiento interactivo mágico en realidad aumentada con sus amigos o familiares y compártalas en las redes sociales, ¡es muy simple!
        </>,
        giftSet: <>
            <p>
                Para encargar AR-sets con entrega a Ucrania, < a target ="_blank" target ="_blank" href='https://prom.ua/c667862-koza-style.html?product_group=99301489' style={{ color: 'purple' }}>haga clic aquí.</a><br /> Para pedir sets AR con entrega FUERA de Ucrania, < a target ="_blank" href='/delivery-form' style={{ color: 'purple' }}>haga clic aquí</a>.
            </p>
        </>,
        instructions: <>
            Instrucciones para usar la < a target ="_blank" href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" style={{ color: "purple" }}>aplicación AR Koza Party:</a>
        </>,
        instructionList: <>
            <li>descargar la < a target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY"> AR Koza Party app.</a> </li>
            <li>La parte superior de la pantalla muestra los objetos de origen (platos, tazas, gorras < a  style={{ color: "purple" }} href="/ar-koza-party/ARimages">KOZA‑STYLE</a>, que se pueden encontrar en las cadenas minoristas Silpo, Класс, Рост), al pasar el cursor sobre los cuales aparecen escenas de juegos y minijuegos de realidad aumentada.</li>
            <li>Para un inicio más rápido de las escenas del juego y los minijuegos, no debe haber deslumbramiento en el objeto fuente. Intente iluminar uniformemente el sujeto fuente.</li>
            <li>En cada escena puede hacer clic en algo.</li>
            <li>Para compartir con sus amigos una foto con un momento interesante, presione el botón Cámara y Compartir.</li>
            <li>Para cerrar la escena, presione el botón Atrás.</li>
        </>
    },
    DE: {
        description1: <>
            Verleihen Sie einer Party mit Freunden oder der&nbsp;Familie&nbsp;eine&nbsp;fröhliche Stimmung!
        </>,
        description2: <> Alles was Sie tun müssen, ist die < a target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank">AR Koza Party App </a> herunterzuladen, Bilder auf Pappbechern, Tellern oder Urlaubsmützen durch die Kamera Ihres Smartphones zu sehen und der Zauber beginnt...<br />
            Für jede der vier Serien des < a  style={{ color: "purple" }} href="/ar-koza-party/ARimages" >KOZA&#8209;STYLE Papierprodukte </a>:
        </>,
        description3: <>
            hat die < a target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank">AR Koza Party app</a> ein eigenes Augmented Reality Entertainment. Machen Sie mit Ihren Freunden oder Ihrer Familie Fotos von magischer interaktiver Unterhaltung in Augmented Reality und teilen Sie sie in sozialen Netzwerken - es ist ganz einfach!
        </>,
        giftSet: <>
            <p>
                Um AR-sets mit Lieferung in die ganze Ukraine zu bestellen, < a target ="_blank" target ="_blank" href='https://prom.ua/c667862-koza-style.html?product_group=99301489' style={{ color: 'purple' }}>klicken Sie hier.</a> <br /> Um AR-sets mit Lieferung AUSSERHALB der Ukraine zu bestellen, < a target ="_blank" href='/delivery-form' style={{ color: 'purple' }}>klicken Sie hier</a>.
            </p>
        </>,
        instructions: <>
            Anweisungen zur Verwendung < a target ="_blank" href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" style={{ color: "purple" }}> der AR Koza Party App:</a>
        </>,
        instructionList: <>
            <li>Lade die < a target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY">AR Koza Party App</a> herunter.</li>
            <li>Der obere Teil des Bildschirms zeigt die Quellobjekte (Teller, Tassen, Kappen < a  style={{ color: "purple" }} href="/ar-koza-party/ARimages">KOZA‑STYLE</a>, die in den Handelsketten Silpo, Класс, Рост zu finden sind), wenn Sie mit der Maus darüber fahren, erscheinen Spielszenen und Minispiele Augmented Reality.</li>
            <li>Für einen schnelleren Start von Spielszenen und Minispielen sollte das Quellobjekt nicht geblendet werden (keine Blendung sein soll). Versuchen Sie, das Quellmotiv gleichmäßig zu beleuchten.</li>
            <li>In jeder Spielszene kann man auf etwas klicken.</li>
            <li>Um ein Foto mit einem interessanten Moment mit Ihren Freunden zu teilen, drücken Sie die Taste Kamera und Teilen.</li>
            <li>Um die Szene zu schließen, drücken Sie die Zurück-Taste.</li>
        </>
    },
    UA: {
        description1: <>
            Додайте веселого настрою<br /> до вечірки з друзями чи родиною!
        </>,
        description2: <> Для цього треба лише завантажити < a  style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank">додаток AR Koza Party,</a> побачити крізь камеру свого смартфону малюнки на паперових стаканах, тарілках чи святкових ковпаках та магія почнеться...<br />
            Для кожної із чотирьох серій < a  style={{ color: "purple" }} href="/ar-koza-party/ARimages" >паперових виробів KOZA&#8209;STYLE</a>: <br />
        </>,
        description3: <>
            в < a target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank">додаток AR Koza Party</a> є окрема розвага у доповненій дійсності. Разом з друзями чи родиною робіть знімки магічних інтерактивних розваг у доповненої дійсності та ділиться ними у соціальних мережах - це дуже просто!
        </>,
        giftSet: <>
            <p>Для замовлення AR-наборів з доставкою по Україні, < a target ="_blank" target ="_blank" href='https://prom.ua/c667862-koza-style.html?product_group=99301489' style={{ color: 'purple' }}>натисніть тут.</a><br /> Для замовлення AR-наборів з доставкою ЗА МЕЖИ Україні, < a target ="_blank" href='/delivery-form' style={{ color: 'purple' }}>натисніть тут</a>.</p>
        </>,
        instructions: <>
            Інструкція по застосуванню < a target ="_blank" href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" style={{ color: "purple" }}>додатка AR Koza Party:</a>
        </>,
        instructionList: <>
            <li>Завантажте < a target ="_blank" style={{ color: "purple" }} href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY"> AR Koza Party додаток.</a></li>
            <li>У верхній частині екрану показані предмети‑джерела (тарілки, стаканчики, ковпаки < a  style={{ color: "purple" }} href="/ar-koza-party/ARimages">KOZA‑STYLE</a>, які можна знайти у роздрібних мережах Сільпо, Класс, Рост), при наведенні на які з'являються ігрові сцени і міні‑ігри доповненої реальності.</li>
            <li>Для більш швидкого запуску ігрових сцен та міні‑ігор на предметі‑джерелі не повинно бути відблисків. Постарайтеся рівномірно висвітлити предмет‑джерело.</li>
            <li>У кожній ігровій сцені можна на щось натиснути.</li>
            <li>Щоб поділиться з друзями фото з цікавим моментом натисніть кнопку Камера, а потім кнопку Поділитися.</li>
            <li>Щоб закрити сцену натисніть кнопку Назад.</li>
        </>
    },
}]
export default Language;