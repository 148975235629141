import React, { useEffect } from "react";
import { connect } from "react-redux";

//Language
import Language from "./Language";

//Router
import { Link } from "react-router-dom";

//Images
import logoMob from "../../images/AforehandStudio_logo_v2.webp";
import logoMob2 from "../../images/favicon-192x192.png";

//Buttons
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";

//Select
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

import "./Header.css";

const theme = createMuiTheme({
    palette: {
        primary: orange,
    },
});


const setElementOnTopById = (id) => {
    var el = document.getElementById(id);
    if (el != null) {
        var y = el.offsetTop;
        var dy = document.getElementsByTagName('nav')[0].getBoundingClientRect().height ?? 50;
        //y= (y-50>0) ? y-dy:y;
        y = y - dy;
        window.scrollTo(0, y);
    }
    else {
        window.location.href = "/main";
    }

};

const checkPathNameForLinkBySwitchingLanguages = (l) => {

    var arr = ['EN', 'UA', 'ES', 'DE', 'en', 'ua', 'es', 'de'];
    for (var i = 0; i < arr.length; i++) {
        if (l == arr[i]) return false;
    }

    return true;

}



const Header = ({ changeLanguage, stateLanguage }) => {
    //destructuring language file
    const [languages] = Language;
    return (
        <React.Fragment>

            <nav className="navbar navbar-expand-lg fixed-top" style={{ backgroundColor: "white", borderBottom: '1px solid orange', paddingLeft: "0.25%", paddingBottom: "0%", paddingRight: '0' }} >

                {/* <div className="container" style={{ paddingLeft: "0.5%", paddingBottom: "0%" }}> */}

                {/* <div className="row" > */}
                <div className="header2" style={{}}>
                    <div className="container">
                        <div className="row" style={{ display: 'flex' }}>
                            <div className="col-2" style={{ margin: 'auto' }}>
                                {/* <Link to="/main" onClick={() => { setElementOnTopById("block1") }} className="navbar-brand header-logo"> */}
                                <Link to="/main" onClick={() => { window.scrollTo(0, 0) }} className="navbar-brand header-logo">
                                    <img src={logoMob} style={{ padding: "0 0 0 0" }} alt="Logo"></img>
                                </Link>
                            </div>
                            <div className="headerText col-9 " style={{ margin: 'auto' }} id="links" >
                                <ul>
                                    {stateLanguage === "EN"
                                        ? <div className="row">
                                            <div className="col-4" style={{}}>
                                                <li>
                                                    <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block1") }} data-content="GAME PROJECTS" style={{ color: "purple" }}>GAME PROJECTS</Link>
                                                </li>
                                            </div>
                                            <div className="col-3" style={{}}>
                                                <li>
                                                    <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block2") }} data-content="OUR SERVICES" style={{ color: "purple" }}>OUR SERVICES</Link>
                                                </li>
                                            </div>
                                            <div className="col-1" style={{ marginLeft: "7%" }}>
                                                <li>
                                                    <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block3") }} data-content="B2B" style={{ color: "purple" }}>B2B</Link>
                                                </li>
                                            </div>
                                            <div className="col" style={{ textAlign: 'center', margin: 'auto' }}>
                                                <Link to="/orderApp" onClick={() => { window.scrollTo(0, 0) }} >
                                                    <button class="btn btn-outline-primary buttonStyle" >AR app for your</button>
                                                </Link>
                                            </div>
                                        </div>
                                        : stateLanguage === "ES"
                                            ? <div className="row">
                                                <div className="col-4" style={{}}>
                                                    <li>
                                                        <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block1") }} data-content="PROYECTO DE JUEGO" style={{ color: "purple" }}>PROYECTO DE JUEGO</Link>
                                                    </li>
                                                </div>
                                                <div className="col-4" style={{ marginLeft: "-4%" }}>
                                                    <li>
                                                        <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block2") }} data-content="NUESTROS SERVICIOS" style={{ color: "purple" }}>NUESTROS SERVICIOS</Link>
                                                    </li>
                                                </div>
                                                <div className="col-1" >
                                                    <li>
                                                        <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block3") }} data-content="B2B" style={{ color: "purple" }}>B2B</Link>
                                                    </li>
                                                </div>
                                                <div className="col-3" style={{ textAlign: 'center', margin: 'auto' }}>
                                                    <Link to="/orderApp" onClick={() => { window.scrollTo(0, 0) }} >
                                                        <button class="btn btn-outline-primary buttonStyle" >App-RA para ti</button>
                                                    </Link>
                                                </div>
                                            </div>
                                            : stateLanguage === "UA"
                                                ? <div className="row" style={{}}>
                                                    <div className="col-4" style={{ marginLeft: "3.3%" }}>
                                                        <li>
                                                            <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block1") }} data-content="ІГРОВІ ПРОЕКТИ" style={{ color: "purple" }}> ІГРОВІ ПРОЕКТИ</Link>
                                                        </li>
                                                    </div>
                                                    <div className="col-4" style={{ marginLeft: "-4%", marginRight: "2.3%" }}>
                                                        <li>
                                                            <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block2") }} data-content="НАШІ СЕРВІСИ" style={{ color: "purple" }}>НАШІ СЕРВІСИ</Link>
                                                        </li>
                                                    </div>
                                                    <div className="col-1" >
                                                        <li>
                                                            <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block3") }} data-content="B2B" style={{ color: "purple" }}>B2B</Link>
                                                        </li>

                                                    </div>
                                                    <div className="col" style={{ textAlign: 'center', margin: 'auto' }}>
                                                        <Link to="/orderApp" onClick={() => { window.scrollTo(0, 0) }} >
                                                            <button class="btn btn-outline-primary buttonStyle" >Твій AR-додаток</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                : <div className="row">
                                                    <div className="col-4" style={{}}>
                                                        <li>
                                                            <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block1") }} data-content="SPIEL&shy;PROJEKTE" style={{ color: "purple" }}>SPIEL&shy;PROJEKTE</Link>
                                                        </li>
                                                    </div>
                                                    <div className="col-5" style={{ marginLeft: "-9%" }}>
                                                        <li>
                                                            <Link className="nav-link content_size tyghbn" onClick={() => { setElementOnTopById("block2") }} data-html="true" data-content="UNSERE &shy;DIENSTLEISTUNGEN&nbsp;&nbsp;&nbsp;" style={{ color: "purple" }}>UNSERE DIENSTLEISTUNGEN</Link>
                                                        </li>
                                                    </div>
                                                    <div className="col-1" >
                                                        <li>
                                                            <Link className="nav-link content_size" onClick={() => { setElementOnTopById("block3") }} data-content="B2B" style={{ color: "purple" }}>B2B</Link>
                                                        </li>
                                                    </div>
                                                    <div className="col" style={{ textAlign: 'center', margin: 'auto' }}>
                                                        <Link to="/orderApp" onClick={() => { window.scrollTo(0, 0) }} >
                                                            <button class="btn btn-outline-primary buttonStyle" >AR-App für Sie</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                    }
                                </ul>
                            </div>

                            <div className="styleLang col-1" style={{ paddingBottom: '0.5%' }} id="account">
                                <div>
                                    <FormControl >
                                        <NativeSelect
                                            inputProps={{
                                                name: "name",
                                                id: "lang",
                                            }}
                                            // onChange={(e) => {
                                            //     var href = document.location.href;
                                            //     var langInHref = href.substring(href.length-2,href.length);

                                            //     if (checkPathNameForLinkBySwitchingLanguages(langInHref)) {
                                            //         changeLanguage(e.target.value.toUpperCase());
                                            //         //changeLanguage(e.target.value);
                                            //         href.substring(0,href.length-2)+e.target.value.toLowerCase();
                                            //     }
                                            //     else {
                                            //         if(href[href.length-1]=='/'){

                                            //         }
                                            //     }


                                            //     var newLink = (pathname=='')? document.location.origin + "/" + pathname + e.target.value.toLowerCase() :
                                            //     //var newLink = document.location.origin + "/" + pathname + e.target.value.toLowerCase();
                                            //     document.location.href = newLink;

                                            // }}


                                            onChange={(e) => {
                                                var href = document.location.pathname;

                                                var asdf = href.split('/');
                                                var pathname = "";
                                                var end = asdf.length - 1;
                                                if (asdf[end].length != 2) end = asdf.length;
                                                for (var i = 1; i < end; i++) {
                                                    if (asdf[i] != "" && asdf[i] != null && asdf[i] != undefined) pathname += '/' + asdf[i];
                                                }

                                                if (checkPathNameForLinkBySwitchingLanguages(pathname)) {
                                                    changeLanguage('EN');
                                                    //changeLanguage(e.target.value);
                                                    pathname += '/'
                                                }
                                                else {
                                                    pathname = '';
                                                }
                                                var newLink = document.location.origin + pathname + e.target.value.toLowerCase();
                                                document.location.href = newLink;
                                                //console.log(newLink);

                                            }}


                                            // onChange={(e) => {
                                            //     var pathname = document.location.pathname.split('/')[1];
                                            //     if (checkPathNameForLinkBySwitchingLanguages(pathname)) {
                                            //         changeLanguage('EN');
                                            //         //changeLanguage(e.target.value);
                                            //         pathname += '/'
                                            //     }
                                            //     else {
                                            //         pathname = '';
                                            //     }
                                            //     var newLink = document.location.origin + "/" + pathname + e.target.value.toLowerCase();
                                            //     document.location.href = newLink;

                                            // }}

                                            value={stateLanguage}
                                        >
                                            <option value="EN">En</option>
                                            <option value="DE">De</option>
                                            <option value="ES">Es</option>
                                            <option value="UA">Ua</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header1">
                    <div className="row" style={{}}>
                        <div className="col-3" style={{}}>
                            <Link to="/main" onClick={() => { setElementOnTopById("block1") }} className="navbar-brand " style={{ margin: 0 }}>
                                <img src={logoMob2} alt="Logo" style={{ padding: "0 0 0 0" }}></img>
                            </Link>
                        </div>

                        <div className="col-6"
                            style={{ textAlign: 'center', margin: 'auto' }}>
                            <Link to={"/orderApp"} >
                                {stateLanguage === "EN"
                                ? <button class="btn btn-outline-primary buttonStyle" >AR app for your</button>
                                 :stateLanguage === "ES"
                                 ? <button class="btn btn-outline-primary buttonStyle" >App-RA para ti</button>
                                 :stateLanguage === "UA"
                                 ? <button class="btn btn-outline-primary buttonStyle" >Твій AR-додаток</button>
                                 : <button class="btn btn-outline-primary buttonStyle" >AR-App für Sie</button>
                                }
                              
                            </Link>

                        </div>
                        <div className="styleLang col-3" id="account" style={{ padding: 0 }}>
                            <FormControl >
                                <NativeSelect
                                    inputProps={{
                                        name: "name",
                                        id: "lang",
                                    }}
                                    onChange={(e) => {
                                        var pathname = document.location.pathname.split('/')[1];
                                        if (checkPathNameForLinkBySwitchingLanguages(pathname)) {
                                            changeLanguage('EN');
                                            //changeLanguage(e.target.value);
                                            pathname += '/'
                                        }
                                        else {
                                            pathname = '';
                                        }
                                        var newLink = document.location.origin + "/" + pathname + e.target.value.toLowerCase();
                                        document.location.href = newLink;

                                    }}
                                    value={stateLanguage}
                                >
                                    <option value="EN">En</option>
                                    <option value="DE">De</option>
                                    <option value="ES">Es</option>
                                    <option value="UA">Ua</option>
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </div>
                </div>
                {/*  </div>    */}
                {/* </div> */}
            </nav>

        </React.Fragment>

    );
};

const mapStateToProps = ({ SignInReducer }) => {
    return {
        token: SignInReducer.token,
        name: SignInReducer.name
    }
}

export default connect(mapStateToProps)(Header);
