import React from 'react';

const SP1 = ({stateLanguage})=>{
    
    
    return <main className="main">
        <div className="container">
            <h1>
                Мармелад 17134568903фывайцук
            </h1>
            </div>
        </main>;
}

export default SP1;