import { Link } from 'react-router-dom';
const Language = [{
    EN: {
        helpUa: <>
            Help Ukraine, order an AR-app from us!
        </>,
        text2: <>
            We are Ukrainian company “Aforehand Studio” and we create excellent <strong>Augmented Reality</strong> (<strong>AR</strong>) mobile apps that definitely help our clients marketing and branding, significantly support growth of business and bring it to the next level.
        </>,
        text3: <>
            We produce high quality software that is affordable and flexible.
        </>,
        text4: <>
            <a href="https://bit.ly/3v7Gck0" target='_blank' style={{ color: "purple" }}>AR&nbsp;Koza&nbsp;Party</a> (<a href="https://bit.ly/3v7Gck0" target='_blank'>https://bit.ly/3v7Gck0</a>) created by our team is a good example of our <strong>AR</strong>&nbsp;Android&#8209;app with 4 different <strong>AR</strong>&#8209;scenes for users to play, have fun and share photos.
            We have developed <a href="https://bit.ly/3v7Gck0" target='_blank' style={{ color: "purple" }}>AR&nbsp;Koza&nbsp;Party</a> app for KOZA-STYLE Company – Ukrainian manufacturer of paper tableware.
        </>,
        text5: <>
            Typically, interactive <strong>AR</strong> content consists of in <a style={{ color: "#9f12e0" }}>3D-</a> or <a style={{ color: "#4912e0" }}>2D-</a>objects on a screen of device, the positions, colors, behavior or other parameters of which users can control, such as by touching the screen.

        </>,
        text6: <>
            The most used projects are simple <strong>AR</strong>&#8209;apps where interactive <strong>AR</strong> content is triggered by recognition of special images-markers such as labels, business cards, marketing materials.
        </>,
        text7: <>
            Timeframe and scope of work are the most important factors for defining the price of an <strong>AR</strong>&#8209;app.
        </>,
        text8: <>
            There is an unlimited variety of a <a style={{ color: "#9f12e0" }}>3D-</a>content available for your <strong>AR</strong>&#8209;app, however the more realistic and animated it is, the more expensive it gets.
        </>,
        text9: <>
            A simple <strong>AR</strong>&#8209;app is the best, the fastest and an inexpensive way to start your marketing campaign to boost your business right now.
        </>,
        directions: <>
            <p className="col-form-label">To order an <strong>AR</strong> application for advertising or your business, fill out and send the form.</p>
            <p className="col-form-label"> We will contact you within 24 hours.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Email address</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted"  style={{fontSize:'1em'}}>We'll never share your email with anyone else.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Text message</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse" style={{fontSize:'1em'}}>
                I accept <Link to='/privacy-police' style={{ color: 'purple' }}>Privacy Policy </Link>, as well as <Link to='/user-argeement' style={{ color: 'purple' }}>Term of Use</Link>.
            </label>
        </>,
        buttonText: <>
            Send
        </>
    },
    ES: {
        helpUa: <>
          Ayuda a Ucrania, solicita la aplicación RA en nuestra empresa!
        </>,
        text2: <>
        Somos la empresa ucraniana “Aforehand Studio” y creamos aplicaciones móviles de <strong>Realidad Aumentada</strong> (<strong>RA</strong>) que ayudan significativamente a nuestros clientes en el marketing y el branding, expandiendo en gran medida su negocio y avanzando.
        </>,
        text3: <>
            Producimos software de alta calidad que es asequible y flexible.
        </>,
        text4: <>
        La aplicación <a href="https://bit.ly/3v7Gck0" target='_blank' style={{ color: "purple" }}>AR&nbsp;Koza&nbsp;Party</a> (<a href="https://bit.ly/3v7Gck0" target='_blank'>https://bit.ly/3v7Gck0</a>) creada por nuestro equipo es un buen ejemplo de una aplicación Android <strong>RA</strong> con cuatro escenas <strong>RA</strong> diferentes donde los usuarios pueden jugar, divertirse y compartir fotos.            
        Hemos desarrollado la aplicación <a href="https://bit.ly/3v7Gck0" target='_blank' style={{ color: "purple" }}>AR&nbsp;Koza&nbsp;Party</a> para KOZA&#8209;STYLE, un fabricante ucraniano de artículos de papel.
        </>,
        text5: <>
        Por lo general, el contenido de <strong>RA</strong> interactiva consiste en objetos <a style={{ color: "#9f12e0" }}>3D-</a> o <a style={{ color: "#4912e0" }}>2D-</a> en la pantalla del dispositivo, la posición, el color, el comportamiento u otros parámetros que los usuarios pueden controlar, como tocar la pantalla.        
        </>,
        text6: <>
        La mayor demanda son las aplicaciones <strong>RA</strong> simples en las que el contenido <strong>RA</strong> interactivo se ejecuta mediante el reconocimiento de marcadores de imagen especiales como etiquetas, tarjetas de visita o materiales de marketing.
        </>,
        text7: <>
           Términos y alcance del trabajo-las características más importantes que forman el costo de la aplicación de <strong>RA</strong>.
        </>,
        text8: <>
        Puede imaginar una variedad de contenido <a style={{ color: "#9f12e0" }}>3D-</a> en su aplicación de <strong>RA</strong>, pero cuanto más realista sea el contenido animado <a style={{ color: "#9f12e0" }}>3D-</a>digital que desea tener en él, más caro será el desarrollo de su aplicación <strong>RA</strong>.
        </>,
        text9: <>
        Por lo tanto, una simple aplicación de <strong>RA</strong> es la mejor manera de iniciar una empresa de marketing de forma rápida y económica que ampliará en gran medida su negocio y avanzará mucho.
        </>,
        directions: <>
            <p className="col-form-label">Para solicitar una solicitud de <strong>RA</strong> para publicidad o su negocio, complete y envíe el formulario.</p>
            <p className="col-form-label">Nos comunicaremos con usted en un plazo de 24 horas.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Correo electrónico</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted"  style={{fontSize:'1em'}}>Nunca compartiremos su correo electrónico con nadie más.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Nombre</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Mensaje de texto</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse" style={{fontSize:'1em'}}>
                Estoy de acuerdo con la <Link to='/privacy-police' style={{ color: 'purple' }}>Política de privacidad </Link>  y los <Link to='/user-argeement' style={{ color: 'purple' }}>Términos de uso</Link>.
            </label>
        </>,
        buttonText: <>
            Enviar
        </>
    },
    DE: {
        helpUa: <>
          Helfen Sie der Ukraine, bestellen Sie eine AR-App bei uns!
        </>,
        text2: <>
      Wir sind das ukrainische Unternehmen „Aforehand Studio“ und wir erstellen hervorragende <strong>Augmented&nbsp;Reality&#8209;Apps</strong> für Handys (<strong>AR&nbsp;Apps</strong>), die definitiv das Marketing und Branding unserer Kunden unterstützen, das Geschäftswachstum erheblich unterstützen und es auf die nächste Stufe bringen.
        </>,
        text3: <>
           Wir produzieren qualitativ hochwertige Software, die erschwinglich und flexibel ist.
        </>,
        text4: <>
        Die von unserem Team erstellte   <a href="https://bit.ly/3v7Gck0" target='_blank' style={{ color: "purple" }}>AR&nbsp;Koza&nbsp;Party App</a> (<a href="https://bit.ly/3v7Gck0" target='_blank'>https://bit.ly/3v7Gck0</a>) ist ein gutes Beispiel für eine <strong>Android&#8209;AR&#8209;App</strong> mit vier verschiedenen <strong>AR</strong>-Szenen für Benutzer zu spielen, Spaß zu haben und Fotos zu teilen.
        Wir haben die <a href="https://bit.ly/3v7Gck0" target='_blank' style={{ color: "purple"}}>AR&nbsp;Koza&nbsp;Party</a> App für die Firma KOZA-STYLE  entwickelt – einem ukrainischen Hersteller von Papiergeschirr.
        </>,
        text5: <>
        Typischerweise bestehen interaktive  <strong>AR</strong>&#8209;Inhalte aus <a style={{ color: "#9f12e0" }}>3D</a>- oder <a style={{ color: "#4912e0" }}>2D-</a>Objekten auf einem Bildschirm eines Geräts befinden und deren Position, Farbe, Verhalten oder andere Parameter Benutzer beispielsweise durch Berühren des Bildschirms steuern können. 
        </>,
        text6: <>
        Am häufigsten verwendeten Projekte sind einfache <strong>AR</strong>&#8209;Apps, bei denen interaktive <strong>AR</strong>&#8209;Inhalte durch die Erkennung spezieller Bilder – Markierungen wie Etiketten, Visitenkarten, Marketingmaterialien – ausgelöst werden.
        </>,
        text7: <>
           Zeitrahmen und Arbeitsumfang sind die wichtigsten Faktoren für die Festlegung des Preises einer <strong>AR</strong>&#8209;App. 
        </>,
        text8: <>
        Es gibt eine unbegrenzte Vielfalt an <a style={{ color: "#9f12e0" }}>3D-</a>Inhalten für Ihre <strong>AR</strong>&#8209;App, aber je realistischer und animierter sie ist, desto teurer wird sie.
        </>,
        text9: <>
        Eine einfache <strong>AR</strong>&#8209;App ist der beste, schnellste und kostengünstigste Weg, um Ihre Marketingkampagne zu starten, um Ihr Geschäft jetzt anzukurbeln.
        </>,
        directions: <>
            <p className="col-form-label">Um eine <strong>AR</strong>&#8209;Anwendung für Werbung oder Ihr Unternehmen zu bestellen, füllen Sie das Formular aus und senden Sie es ab.</p>
            <p className="col-form-label"> Wir werden Sie innerhalb von 24 Stunden kontaktieren.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">E-Mail-Addresse</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted"  style={{fontSize:'1em'}}>Wir werden Ihre E-Mail niemals mit anderen teilen.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Textnachricht</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse" style={{fontSize:'1em'}}>
                Ich stimme <Link to='/privacy-police' style={{ color: 'purple' }}>den Datenschutzbestimmungen</Link> und <Link to='/user-argeement' style={{ color: 'purple' }}>Nutzungsbedingungen</Link> zu.
            </label>
        </>,
        buttonText: <>
            senden
        </>
    },
    UA: {
        helpUa: <>
            Допоможіть Україні, замовте AR-додаток у нас!
        </>,
        text2: <>
            Ми – українська компанія і ми створюємо чудові мобільні додатки доповненої реальності (<strong>AR</strong>), які, допомагають нашим клієнтам у маркетингу та брендингу, значно розширюючи їхній бізнес і виводячи його далеко вперед.
        </>,
        text3: <>
        </>,
        text4: <>
            Ми виробляємо високоякісне програмне забезпечення, яке є доступним і гнучким.
            <a href="https://bit.ly/3v7Gck0" target='_blank' style={{ color: "purple"}}>AR&nbsp;Koza&nbsp;Party</a> (<a href="https://bit.ly/3v7Gck0" target='_blank'>https://bit.ly/3v7Gck0</a>) від нашої команди є гарним прикладом нашого <strong>AR</strong>&nbsp;Android&#8209;додатка з чотирма різними <strong>AR</strong>-сценами, щоб користувачі могли грати, розважатися та ділитися фотографіями.
            Ми розробили додаток <a href="https://bit.ly/3v7Gck0" target='_blank' style={{ color: "purple"}}>AR&nbsp;Koza&nbsp;Party</a> для компанії KOZA-STYLE – українського виробника паперового посуду.
        </>,
        text5: <>
            Як правило, інтерактивний вміст <strong>AR</strong> складається з <a style={{ color: "#9f12e0" }}>3D-</a> або <a style={{ color: "#4912e0" }}>2D-</a>об’єктів, розміщених на екрані пристрою, положенням, кольорами, поведінкою або іншими параметрами яких користувачі можуть керувати, наприклад, торкаючись екрана.
        </>,
        text6: <>
            Найбільш затребуваними є найпростіші <strong>AR</strong>&#8209;додатки, де інтерактивний <strong>AR</strong>&#8209;контент запускається розпізнаванням спеціальних зображень-маркерів, таких як етикетки, візитки, маркетингові матеріали.
        </>,
        text7: <>
            Хронометраж і обсяг робіт – найважливіші характеристики, які формують вартість <strong>AR</strong>&#8209;додатка.
        </>,
        text8: <>
        Ви можете уявити різноманітний <a style={{ color: "#9f12e0" }}>3D-</a>контент у своєму <strong>AR</strong>&#8209;додатку, але чим реалістичніше анімований цифровий <a style={{ color: "#9f12e0" }}>3D&#8209;</a>контент<a></a> ви хочете мати, тим дорожче буде розробка вашого <strong>AR</strong>&#8209;додатка.
            
        </>,
        text9: <>
        Отже, найпростіший <strong>AR</strong>&#8209;додаток – це найкращий спосіб швидко та недорого почати маркетингову кампанію, що значно розширить ваш бізнес та просуне його далеко вперед.
            
        </>,
        directions: <>
            <p className="col-form-label">Для замовлення свого <strong>AR</strong>&#8209;додатка заповніть та відправте форму.</p>
            <p className="col-form-label"> Ми зв'яжемося з вами протягом 24 годин.</p>
        </>,
        emailText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Електронна пошта</label>
        </>,
        alertText: <>
            <small id="emailHelp" className="form-text text-muted" style={{fontSize:'1em'}}>Ми ніколи не поділимось вашою електронною поштою з кимось іншим.</small>
        </>,
        nameText: <>
            <label htmlFor="exampleInputEmail1" className="form-label mt-4">Ім'я</label>
        </>,
        descriptionText: <>
            <label htmlFor="exampleTextarea" className="form-label mt-4">Текстове повідомлення</label>
        </>,
        privacyText: <>
            <label className="form-check-label" htmlFor="termsOfUse" style={{fontSize:'1em'}} >
                Я згоден з <Link to='/privacy-police' style={{ color: 'purple' }}>Політикою конфіденційності</Link>, а також <Link to='/user-argeement' style={{ color: 'purple' }}>Умовами використання</Link>
            </label>
        </>,
        buttonText: <>
            Відправити
        </>
    },
}]
export default Language;
//{stateLanguage && languages[stateLanguage].description1}