import React from 'react'
import { Link } from 'react-router-dom';
import * as emailjs from 'emailjs-com';
import Language from "./Language";
import './ArApp.css';
import unicornLogo from '../../images/unicorn_logo.png';
import cupAR from '../../images/stakanMishkaAr.png';
import catAR from '../../images/catAr.png';

export default class ArApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLanguage: { ...props },
            IsSended: false,
            isEmailOk: false,
            isNameOk: false,
            isMessageOk: false,
            isTermsOfUseOk: false,
            Info: {
                username: "",
                mailfrom: "",
                message: "",
                termsOfUse: false
            },
            sendFormPropertis: {
                service: "service_gf42tma",
                template: "template_oeasndb",
                selector: "#mailform",
                userId: "user_zr3jNsGItXGHKG7MIfu8J"
            }
        };
    }

    componentDidUpdate() {
        console.log(this.state)
    }

    onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        var Info = this.state.Info;
        Info[name] = value;
        this.setState({ Info });
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            IsSended: false,
            isEmailOk: false,
            isNameOk: false,
            isMessageOk: false,
            isTermsOfUseOk: false
        });
    }

    onFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        var Info = this.state.Info;
        Info[name] = value;
        this.setState({ Info });
    };

    sendMessege = (e) => {
        const { service, template, userId } = this.state.sendFormPropertis;
        const { Info } = this.state;
        var templateParams = {
            head: "Aforehand Studio Заказ приложения",
            username: Info.username,
            mailfrom: Info.mailfrom,
            message1: "Сообщение: " + Info.message
        };
        emailjs
            .send(
                service,
                template,
                templateParams,
                userId
            );
        this.setState({ IsSended: true });
    };

    checkFields = () => {
        var inputEmail = document.getElementById("exampleInputEmail1")?.value;
        var inputName = document.getElementById("exampleInputEmail2")?.value;
        var message = document.getElementById("exampleTextarea")?.value;
        var termsOfUse = document.getElementById("termsOfUse")?.checked;

        var Info = {
            mailfrom: inputEmail,
            username: inputName,
            message
        };
        var isEmailOk = inputEmail && (inputEmail.indexOf('@') > -1) && (inputEmail.indexOf('.') > -1);
        var isNameOk = inputName && true;
        var isMessageOk = message && true;
        var isTermsOfUseOk = termsOfUse && true;

        this.setState({ isEmailOk, isMessageOk, isNameOk, isTermsOfUseOk, Info });
    }

    render() {
        const [languages] = Language;
        const { stateLanguage } = this.props;
        if (this.state.IsSended) {
            const notification1 = stateLanguage === "EN" ? "Message sent successfully!" :
                stateLanguage === "ES" ? "¡Mensaje enviado con éxito!" :
                    stateLanguage === "UA" ? "Повідомлення успішно надіслано!" :
                        "Nachricht erfolgreich gesendet!";
            const notification2 = stateLanguage === "EN" ? " You will be contacted shortly." :
                stateLanguage === "ES" ? " Nos comunicaremos con usted en breve." :
                    stateLanguage === "UA" ? " Незабаром з вами зв'яжуться." :
                        " Sie werden in Kürze kontaktiert.";
            const buttontext = stateLanguage === "EN" ? "Return to the main page" :
                stateLanguage === "ES" ? "Regresar a la pagina principal" :
                    stateLanguage === "UA" ? "Повернення до головної сторінки" :
                        "Zurück zur Hauptseite";
            return (

                <main className="main">
                    <div className="row">
                        <div className=" alert">
                            <br />
                            <a style={{ color: "black" }}>{notification1}{notification2}</a>
                            <br />
                            <br />
                            <div>
                                <Link className="col-12" to="/main" style={{ float: 'right' }} >
                                    <button className="btn btn-info buttonStyle" >{buttontext}</button>
                                </Link>
                            </div>
                            <br />
                        </div>
                    </div>
                </main>
            );
        }
        const emailplaceholder = stateLanguage === "EN" ? "Enter your email" :
            stateLanguage === "ES" ? "Introduce tu correo electrónico" :
                stateLanguage === "UA" ? "Введіть свою електронну пошту" :
                    "Geben sie ihre E-Mail Adresse ein";
        const nameplaceholder = stateLanguage === "EN" ? "Enter your name" :
            stateLanguage === "ES" ? "Introduzca su nombre" :
                stateLanguage === "UA" ? "Введіть своє ім'я" :
                    "Gib deinen Namen ein";
        const textplaceholder = stateLanguage === "EN" ? "Any coments" :
            stateLanguage === "ES" ? "Introduzca su nombre" :
                stateLanguage === "UA" ? "Коментарі" :
                    "Gib deinen Namen ein";
        const warningtext = stateLanguage === "EN" ? "Required field" :
            stateLanguage === "ES" ? "Campo requerido" :
                stateLanguage === "UA" ? "Обов'язкове поле" :
                    "Pflichtfeld";
        var content;
        const scrWidth = window.screen.availWidth;
        if (scrWidth > 479) {
            content =
                <form id="mailform" className="col-10 justify-content-center cont2">



                    <div className='col-11' style={{ margin: '0 auto' }}>
                        <div className='col-12' style={{ margin: '0 auto' }}>
                            <div className='col-12' style={{ margin: '0 auto' }}>
                                <div className='col-12' style={{ margin: '0 auto' }}>
                                    <div className='col-12' style={{ margin: '0 auto' }}>
                                        <div className='col-12' style={{ margin: '0 auto' }}>
                                            <div className='col-12' style={{ margin: '0 auto' }}>
                                                <div className='col-12' style={{ margin: '0 auto' }}>

                                                    <div className={this.state.isEmailOk ? "form-group has-success" : "form-group has-danger"}>
                                                        {stateLanguage && languages[stateLanguage].emailText}

                                                        <input
                                                            type="email"
                                                            id="emailField"
                                                            className={this.state.isEmailOk ? "form-control is-valid" : "form-control is-invalid"}
                                                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={emailplaceholder}
                                                            name="mailfrom"
                                                            onChange={() => { this.checkFields() }}
                                                        />
                                                        {!this.state.isEmailOk ? <div class="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}

                                                        {stateLanguage && languages[stateLanguage].alertText}


                                                        {stateLanguage && languages[stateLanguage].nameText}

                                                        <input
                                                            type="text"
                                                            className={this.state.isNameOk ? "form-control is-valid" : "form-control is-invalid"}
                                                            id="exampleInputEmail2"
                                                            aria-describedby="emailHelp"
                                                            placeholder={nameplaceholder}
                                                            name="username"
                                                            onChange={() => { this.checkFields() }}
                                                        />
                                                        {!this.state.isNameOk ? <div class="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}


                                                        {stateLanguage && languages[stateLanguage].descriptionText}

                                                        <textarea id="exampleTextarea" rows="3"
                                                            name="message"
                                                            placeholder={textplaceholder}
                                                            className={this.state.isMessageOk ? "form-control is-valid" : "form-control is-invalid"}
                                                            onChange={() => { this.checkFields() }}
                                                        />
                                                        {!this.state.isMessageOk ? <div class="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}
                                                        <div className="form-check" style={{ paddingTop: '3%', marginLeft: '0.9rem' }}>
                                                            <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} type="checkbox" id="termsOfUse"
                                                                name="username"
                                                                onChange={() => { this.checkFields() }}
                                                            />
                                                            <div style={{ fontSize: "1em" }}>
                                                                {stateLanguage && languages[stateLanguage].privacyText}
                                                            </div>
                                                        </div>


                                                    </div>{
                                                        this.state.isEmailOk && this.state.isNameOk && this.state.isTermsOfUseOk ?
                                                            <button style={{ fontSize: "1.25em" }} type="submit" className="btn btn-outline-success" onClick={this.sendMessege} >
                                                                {stateLanguage && languages[stateLanguage].buttonText}
                                                            </button>
                                                            : <button style={{ fontSize: "1.25em" }} type="submit" className="btn btn-outline-success" disabled>
                                                                {stateLanguage && languages[stateLanguage].buttonText}
                                                            </button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                </form>

        }
        else {
            content =
                <div className="row">
                    <form id="mailform" className="col-12 justify-content-center cont1">
                        <div className="form-group row description_text" style={{ paddingTop: '2em', marginBottom: '0%' }}>
                            <hr />
                            {stateLanguage === "EN"
                                ? <div>
                                    <p className="col-form-label">To order an AR application for advertising or your business, fill out and send the form.</p>
                                    <p className="col-form-label"> We will contact you within 24 hours.</p>
                                </div>
                                : stateLanguage === "ES"
                                    ? <div>
                                        <p className="col-form-label">Para solicitar una solicitud de RA para publicidad o su negocio, complete y envíe el formulario.</p>
                                        <p className="col-form-label">Nos comunicaremos con usted en un plazo de 24 horas.</p>
                                    </div>
                                    : stateLanguage === "UA"
                                        ? <div>
                                            <p className="col-form-label">Для замовлення AR додатка для реклами або свого бізнесу, заповніть та відправте форму.</p>
                                            <p className="col-form-label"> Ми зв'яжемося з вами протягом 24 годин.</p>
                                        </div>
                                        : <div>
                                            <p className="col-form-label">Um eine AR-Anwendung für Werbung oder Ihr Unternehmen zu bestellen, füllen Sie das Formular aus und senden Sie es ab.</p>
                                            <p className="col-form-label"> Wir werden Sie innerhalb von 24 Stunden kontaktieren.</p>
                                        </div>
                            }

                        </div>

                        <div className={this.state.isEmailOk ? "form-group has-success" : "form-group has-danger"}>

                            {stateLanguage === "EN"
                                ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Email address</label>
                                : stateLanguage === "ES"
                                    ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Correo electrónico</label>
                                    : stateLanguage === "UA"
                                        ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Електронна пошта</label>
                                        : <label htmlFor="exampleInputEmail1" className="form-label mt-4">E-Mail-Addresse</label>}



                            <input
                                type="email"
                                id="emailField"
                                className={this.state.isEmailOk ? "form-control is-valid" : "form-control is-invalid"}
                                id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={emailplaceholder}
                                name="mailfrom"
                                onChange={() => { this.checkFields() }}
                            />

                            {!this.state.isEmailOk ? <div class="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}

                            {stateLanguage === "EN"
                                ? <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                : stateLanguage === "ES"
                                    ? <small id="emailHelp" className="form-text text-muted">Nunca compartiremos su correo electrónico con nadie más.</small>
                                    : stateLanguage === "UA"
                                        ? <small id="emailHelp" className="form-text text-muted">Ми ніколи не поділимось вашою електронною поштою з кимось іншим.</small>
                                        : <small id="emailHelp" className="form-text text-muted">Wir werden Ihre E-Mail niemals mit anderen teilen.</small>}

                            {stateLanguage === "EN"
                                ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>
                                : stateLanguage === "ES"
                                    ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Nombre</label>
                                    : stateLanguage === "UA"
                                        ? <label htmlFor="exampleInputEmail1" className="form-label mt-4">Ім'я</label>
                                        : <label htmlFor="exampleInputEmail1" className="form-label mt-4">Name</label>}
                            <input
                                type="text"
                                className={this.state.isNameOk ? "form-control is-valid" : "form-control is-invalid"}
                                id="exampleInputEmail2"
                                aria-describedby="emailHelp"
                                placeholder={nameplaceholder}
                                name="username"
                                onChange={() => { this.checkFields() }}
                            />
                            {!this.state.isNameOk ? <div class="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}



                            {stateLanguage === "EN"
                                ? <label htmlFor="exampleTextarea" className="form-label mt-4">Text message</label>
                                : stateLanguage === "ES"
                                    ? <label htmlFor="exampleTextarea" className="form-label mt-4">Mensaje de texto</label>
                                    : stateLanguage === "UA"
                                        ? <label htmlFor="exampleTextarea" className="form-label mt-4">Текстове повідомлення</label>
                                        : <label htmlFor="exampleTextarea" className="form-label mt-4">Textnachricht</label>}

                            <textarea id="exampleTextarea" rows="3"
                                name="message"
                                className={this.state.isMessageOk ? "form-control is-valid" : "form-control is-invalid"}
                                onChange={() => { this.checkFields() }}
                            />
                            {!this.state.isMessageOk ? <div class="invalid-feedback">{warningtext}</div> : <React.Fragment></React.Fragment>}
                            <div className="form-check" style={{ paddingTop: '3%', marginLeft: '20px' }}>
                                <input className={this.state.isTermsOfUseOk ? "form-check-input" : "form-check-input wrongCheckBox"} type="checkbox" id="termsOfUse"
                                    name="username"
                                    onChange={() => { this.checkFields() }}

                                />

                                {stateLanguage === "EN"
                                    ? <label className="form-check-label" style={{ fontSize: "16px" }} htmlFor="termsOfUse">
                                        I accept <Link to='/privacy-police' style={{ color: 'purple' }}>Privacy Policy, </Link>
                                        <br />
                                        as well as <Link to='/user-argeement' style={{ color: 'purple' }}>Term of Use</Link>.
                                    </label>
                                    : stateLanguage === "ES"
                                        ? <label className="form-check-label" style={{ fontSize: "16px" }} htmlFor="termsOfUse">
                                            Estoy de acuerdo con la <Link to='/privacy-police' style={{ color: 'purple' }}>Política de privacidad </Link>
                                            <br />
                                            y los <Link to='/user-argeement' style={{ color: 'purple' }}>Términos de uso</Link>.

                                        </label>
                                        : stateLanguage === "UA"
                                            ? <label className="form-check-label" style={{ fontSize: "16px" }} htmlFor="termsOfUse">
                                                Я згоден з <Link to='/privacy-police' style={{ color: 'purple' }}>Політикою конфіденційності,</Link>
                                                <br />
                                                а також <Link to='/user-argeement' style={{ color: 'purple' }}>Умовами використання</Link>.
                                            </label>
                                            : <label className="form-check-label" style={{ fontSize: "16px" }} htmlFor="termsOfUse">
                                                Ich stimme <Link to='/privacy-police' style={{ color: 'purple' }}>den Datenschutzbestimmungen</Link>
                                                <br />
                                                und <Link to='/user-argeement' style={{ color: 'purple' }}>Nutzungsbedingungen</Link> zu.
                                            </label>}

                            </div>
                        </div>




                        {
                            this.state.isEmailOk && this.state.isNameOk && this.state.isTermsOfUseOk ?
                                <button type="submit" className="btn btn-outline-success" onClick={this.sendMessege} >
                                    {stateLanguage && languages[stateLanguage].buttonText}
                                </button>
                                : <button type="submit" className="btn btn-outline-success" disabled>
                                    {stateLanguage && languages[stateLanguage].buttonText}
                                </button>
                        }


                    </form >


                </div>

        }


        var offerHtml = (!window.mobileCheck()) ? <React.Fragment>
            <div className='col-10' style={{ margin: '0 auto' }}>
                <div className='row'>
                    <div className='row col-12 np' style={{ textAlign: 'center' }}>
                        <p><br /></p>
                        <h3>
                            {stateLanguage && languages[stateLanguage].helpUa}
                        </h3>
                        <p><br /></p>
                    </div>

                    <div className='row col-12 np'>
                        <div className='col-12 np'>
                            {/* p-> text-indent:20px;    style={{textIndent:'20px'}} */}
                            <p>
                                {stateLanguage && languages[stateLanguage].text2}

                            </p>
                            <p>
                                {stateLanguage && languages[stateLanguage].text3}

                            </p>
                        </div>

                    </div>
                    <div className='row col-12 np' style={{ marginBottom: '0em' }}>
                        <div className='col np' style={{ display: 'flex' }}>
                            <img className='imgPadLeft imgCenter' src={unicornLogo} alt='unicorn' />
                        </div>
                        <div className='col-10 np' style={{ paddingRight: '1%' }}>

                            <p style={{}}>
                                {stateLanguage && languages[stateLanguage].text4}


                            </p>
                        </div>


                    </div>
                    <div className='row col-12 np'>
                        <div className='col np' style={{ paddingLeft: '2.24em', display: 'flex' }}>
                            <p style={{ margin: 'auto' }}>
                                {stateLanguage && languages[stateLanguage].text5}

                            </p>
                        </div>
                        <div className='col-5 np' style={{ display: 'flex' }}>
                            <img className='imgPadRight2' style={{ justifySelf: 'center' }} src={cupAR} alt="cupAr" />
                        </div>

                    </div>

                    <div className='row col-12 np' style={{ }} >
                        <div className='col-4 np'>
                            <img className='imgPadLeft' src={catAR} alt='catAr' style={{ float: 'right', width: '100%' }} />
                        </div>
                        <div className='col np' style={{ display: 'flex' }}>
                            <div style={{ margin: 'auto' }}>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text6}
                                </p>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text7}

                                </p>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text8}
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className='row col-12 np'>
                        <div className='col-12 np' style={{paddingTop:'1em'}}>
                            <p>
                                {stateLanguage && languages[stateLanguage].text9}

                            </p>

                        </div>
                    </div>
                    <div className="form-group row description-text" style={{ marginTop:'0', paddingLeft: '0', marginBottom: '0%' }}>
                        {stateLanguage && languages[stateLanguage].directions}
                    </div>
                </div>
            </div>
        </React.Fragment >
            :
            <React.Fragment>
                <div className='col-12' style={{ margin: '50px auto 0' }} >
                    <div className='row'>
                        <div className='row col-12 np' style={{ textAlign: 'center' }}>
                            <p><br /></p>
                            <h3>
                                {stateLanguage && languages[stateLanguage].helpUa}
                            </h3>
                            <p><br /></p>
                        </div>

                        <div className='row col-12 np'>
                            <div className='col-12 np' style={{ marginBottom: '1.5em' }}>
                                {/* p-> text-indent:20px;    style={{textIndent:'20px'}} */}
                                <p>
                                    {stateLanguage && languages[stateLanguage].text2}

                                </p>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text3}

                                </p>
                            </div>

                        </div>
                        <div className='col-12 np' >
                            <img className='center' style={{ width: '30%' }} src={unicornLogo} alt='unicorn' />
                        </div>
                        <div className='col-12 np' style={{ marginBottom: '1.5em' }}>

                            <p style={{}}>
                                {stateLanguage && languages[stateLanguage].text4}


                            </p>
                        </div>




                        <div className='col-12 np'>
                            <img className='center' style={{ width: '80%' }} src={cupAR} alt="cupAr" />
                        </div>
                        <div className='col-12 np' style={{ marginBottom: '1.5em' }}>
                            <p style={{}}>
                                {stateLanguage && languages[stateLanguage].text5}

                            </p>

                        </div>


                        <div className='col-12 np'>
                            <img className='' src={catAR} alt='catAr' style={{ marginBottom: '1.5em', width: '100%' }} />
                        </div>

                        <div className='col-12 np' style={{}}>
                            <p>
                                {stateLanguage && languages[stateLanguage].text6}
                            </p>
                            <p>
                                {stateLanguage && languages[stateLanguage].text7}

                            </p>
                            <p>
                                {stateLanguage && languages[stateLanguage].text8}
                            </p>
                        </div>




                        <div className='row col-12 np'>
                            <div className='col-12 np'>
                                <p>
                                    {stateLanguage && languages[stateLanguage].text9}

                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment >;
        return (
            <main className="main">
                <div className="container">
                    <div className='row' id="offerText">
                        {offerHtml}
                    </div>

                    <div className="row">
                        <div className="col-1 cont2"></div>
                        <div className="col-1 cont1"></div>


                        {content}
                        <div className="col-1 cont2"></div>
                        <div className="col-1 cont1"></div>
                    </div>
                </div>


            </main>
        );
    }
}
