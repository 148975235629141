import React from 'react'
import { Link } from 'react-router-dom';
import arkozalogo from "../../images/icon_v10.png";
import playserviceLogo from "../../images/GooglePlay.png";
import gplayLogo from "../../images/gplay.png";
import playserviceLogoMob from "../../images/gplay.png";
import ar_koza_party_text from "../../images/ar_koza_party_text.webp";
import arkoza_prev1 from "../../images/for_web_hb_775.png";
import arkoza_prev2 from "../../images/for_web_sakura_775.png";
import arGreetingText from "../../images/logo.png";
import arGreetingLogo from "../../images/logoAR.png";
//style
import './MainPage.css';
//import { Button, colors } from '@material-ui/core';

import mainGif from '../../images/slide3_gif.gif';



//const MainPage = ({ stateLanguage }) => {
class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateLanguage: { ...props }
      ,timer:null
    }
  }



  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({
      timer: setInterval(
        ()=>{
          let el = document.getElementById("loppgif");
          if(el){
            el.setAttribute('src',mainGif)
          }
        }
        ,12200)
    });
  }

  render() {

    var needPadding = (window.screen.availWidth < 1059 && window.screen.availWidth > 479);
    const mainClassName = ((needPadding) ? " top-margin" : "");
    //const marginForblocks= Math.round(document.getElementsByTagName('nav')[0]?.getBoundingClientRect().height??0,-2);
    //alert(marginForblocks);
    const { stateLanguage } = this.props;


    var fhdContent = <React.Fragment>
      <div className='container'>
        <div className="col-11" style={{ margin: '0 auto' }}>


          <h3 className="mini-header" id="block3">
            <React.Fragment>B2B</React.Fragment>
          </h3>
          <div className="col-12" >
            <div className="h-100" style={{ margin: '0 auto' }}>
              <div className="row pictureStyle" >
                <div className="col-4">
                  <img className="koza-logo1" src={arkozalogo} alt="AR-KOZA-PARTY LOGO" />
                </div>
                <div className="col-4">
                  <img className="koza-logo2" src={ar_koza_party_text} alt="AR-KOZA-PARTY TEXT" />
                </div>
                <div className="col-4">
                  <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank">
                    <img className="koza-logo3" src={playserviceLogo} alt="GOOGLE PLAY MARKET LOGO" />
                    {/*<img className="koza-logo4" src={playserviceLogoMob} alt="GOOGLE PLAY MARKET LOGO" />*/}
                  </a>
                </div>
              </div>
              <div className='row col-12 ' style={{ margin: '0 auto' }}>
                <div className='col-2'></div>
                <div className='col-4 '>
                  <img src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                </div>
                <div className='col-4'>
                  <img src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" />
                </div>
                {/* <img className="col-4" src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                <img className="col-4" src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" /> */}
              </div>



              <div className="description-text" style={{ textAlign: 'center', marginBottom: '0.1em', fontSize: "1.25em" }}>
                {stateLanguage === "EN"
                  ? <React.Fragment>Augmented reality scenes on <a style={{ color: "purple" }} href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a> cardboard disposable tableware</React.Fragment>
                  : stateLanguage === "ES"
                    ? <React.Fragment>Escenas de juegos de realidad aumentada sobre vajilla desechable de cartón de <a style={{ color: "purple" }} href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>
                    : stateLanguage === "UA"
                      ? <React.Fragment>Ігрові сцени в доповненій реальності на картонному одноразовому посуді фірми <a style={{ color: "purple" }} href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>
                      : <React.Fragment>Augmented Reality Spielszenen auf Einweggeschirr aus Pappe von <a style={{ color: "purple" }} href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>}
              </div>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <Link to={"/ar-koza-party"} style={{ marginTop: "0", marginBottom: "0.5em" }}>
                  {/* <Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage.toLowerCase(): "")} style={{ marginTop: "0", marginBottom: "0.3em" }}>  */}
                  {stateLanguage === "EN"
                    ? <button class="btn btn-outline-primary buttonStyle"  >More details</button>
                    : stateLanguage === "ES"
                      ? <button class="btn btn-outline-primary buttonStyle" >Más detalles</button>
                      : stateLanguage === "UA"
                        ? <button class="btn btn-outline-primary buttonStyle" >Детальніше</button>
                        : <button class="btn btn-outline-primary buttonStyle" >Mehr Details</button>}
                </Link>
              </div>
            </div>
          </div>


          <h3 className="mini-header" id="block1">
            {stateLanguage === "EN"
              ? <React.Fragment>Game projects</React.Fragment>
              : stateLanguage === "ES"
                ? <React.Fragment>Proyectos de juegos </React.Fragment>
                : stateLanguage === "UA"
                  ? <React.Fragment>Ігрові проекти</React.Fragment>
                  : <React.Fragment>Spielprojekte </React.Fragment>}
          </h3>
          <div className="container">
            <div style={{ textAlign: "center", marginTop: "0.3em", marginBottom: "0.3em" }} className="description-text">
              <React.Fragment>Coming Soon...</React.Fragment>
            </div>
          </div>
          <div >
          <div id="block2" >
            <h3 className="mini-header">
              {stateLanguage === "EN"
                ? <React.Fragment>Our services</React.Fragment>
                : stateLanguage === "ES"
                  ? <React.Fragment>Nuestros servicios</React.Fragment>
                  : stateLanguage === "UA"
                    ? <React.Fragment>Наші сервіси</React.Fragment>
                    : <React.Fragment>Unsere Dienstleistungen</React.Fragment>}
            </h3>
            </div>
            <div className="col-12" >
            <div className="h-100" style={{ margin: '0 auto' }}>
              <div className="row pictureStyle" >
                <div className="col-4">
                  <img className="koza-logo1" src={arGreetingLogo} alt="AR-KOZA-PARTY LOGO" />
                </div>
                <div className="col-4">
                  <img className="koza-logo2" src={arGreetingText} alt="AR-KOZA-PARTY TEXT" />
                </div>
                <div className="col-4">
                  <a  >
                    <img className="koza-logo3" src={playserviceLogo} alt="GOOGLE PLAY MARKET LOGO" />
                    {/*<img className="koza-logo4" src={playserviceLogoMob} alt="GOOGLE PLAY MARKET LOGO" />*/}
                  </a>
                </div>
              </div>
              <div className='row col-12 ' style={{ margin: '0 auto' }}>
                <div className='col-2'></div>
                <img id="loppgif" src={mainGif} style={{borderRadius:"4em", backgroundRepeat:"repeat"}}/>
                {/* <img className="col-4" src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                <img className="col-4" src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" /> */}
              </div>

              

              <div className="description-text" style={{ textAlign: 'center', marginBottom: '0.1em', fontSize: "1.25em" }}>
                {stateLanguage === "EN"
                  ? <React.Fragment>The ARgreetings is the platform for communicate with friends through AR-scenes and  models.<br/> Take the plunge into AR with ARgreetings!</React.Fragment>
                  : stateLanguage === "ES"
                    ? <React.Fragment>ARgreetings es la plataforma para comunicarse con amigos a través de AR-escenas y modelos. <br/>¡Sumérjase en AR con ARgreetings! ¡Prueba o conviértete en coautor!</React.Fragment>
                    : stateLanguage === "UA"
                      ? <React.Fragment>ARgreetings — платформа для спілкування з друзями через AR-сцени та моделі.<br/> Пориньте в AR з ARgreetings!</React.Fragment>
                      : <React.Fragment>ARgreetings ist eine Plattform für die Kommunikation mit Freunden über AR-Szenen und -Modelle. <br/>Tauchen Sie mit ARgreetings in AR ein!</React.Fragment>}
              </div>
              
              <div className='col-12' style={{ textAlign: 'center' }}>
                <a href={"https://argreetings.aforehand.com.ua"} targer="_blank"style={{ marginTop: "0", marginBottom: "0.5em" }}>
                  {/* <Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage.toLowerCase(): "")} style={{ marginTop: "0", marginBottom: "0.3em" }}>  */}
                  {stateLanguage === "EN"
                    ? <button class="btn btn-outline-primary buttonStyle"  >More details</button>
                    : stateLanguage === "ES"
                      ? <button class="btn btn-outline-primary buttonStyle" >Más detalles</button>
                      : stateLanguage === "UA"
                        ? <button class="btn btn-outline-primary buttonStyle" >Детальніше</button>
                        : <button class="btn btn-outline-primary buttonStyle" >Mehr Details</button>}
                </a>
              </div>
              
            </div>
          </div>


          </div>
        </div>
      </div>
    </React.Fragment>;

var mobileContent = <React.Fragment>
  <div className='container'>
    <div className="col-11" style={{margin: '0px auto 0'}}>
      <h3 className="mini-header" id="block3">
        <React.Fragment>B2B</React.Fragment>
      </h3>
      <div className="col-12">
        <div className="h-100" style={{margin: '0 auto'}}>
          <div className="row pictureStyle">
            <div className="col-4">
              <img className="koza-logo1" src={arkozalogo} alt="AR-KOZA-PARTY LOGO"/>
            </div>
            <div className="col-4">
              <img className="koza-logo2" src={ar_koza_party_text} alt="AR-KOZA-PARTY TEXT"/>
            </div>
            <div className="col-4">
              <a href="https://play.google.com/store/apps/details?id=com.AFOREHAND.ARKOZAPARTY" target="_blank">
                <img className="koza-logo3" src={playserviceLogo} alt="GOOGLE PLAY MARKET LOGO"/>
                {/*<img className="koza-logo4" src={playserviceLogoMob} alt="GOOGLE PLAY MARKET LOGO" />*/}
              </a>
            </div>
          </div>
          <div className='row col-12 ' style={{margin: '0 auto'}}>
            <div className='col-2'></div>
            <div className='col-4 '>
              <img src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO"/>
            </div>
            <div className='col-4'>
              <img src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO"/>
            </div>
            {/* <img className="col-4" src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                <img className="col-4" src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" /> */}
          </div>


          <div className="description-text" style={{textAlign: 'center', marginBottom: '0.1em', fontSize: "1.25em"}}>
            {stateLanguage === "EN"
                ? <React.Fragment>Augmented reality scenes on <a style={{color: "purple"}}
                                                                 href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a> cardboard
                  disposable tableware</React.Fragment>
                : stateLanguage === "ES"
                    ? <React.Fragment>Escenas de juegos de realidad aumentada sobre vajilla desechable de cartón de <a
                        style={{color: "purple"}} href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>
                    : stateLanguage === "UA"
                        ?
                        <React.Fragment>Ігрові сцени в доповненій реальності на картонному одноразовому посуді фірми <a
                            style={{color: "purple"}}
                            href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>
                        : <React.Fragment>Augmented Reality Spielszenen auf Einweggeschirr aus Pappe von <a
                            style={{color: "purple"}}
                            href="https://kharkov.prom.ua/c667862-koza-style.html">KOZA‑STYLE</a></React.Fragment>}
          </div>
          <div className='col-12' style={{textAlign: 'center'}}>
            <Link to={"/ar-koza-party"} style={{marginTop: "0", marginBottom: "0.5em"}}>
              {/* <Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage.toLowerCase(): "")} style={{ marginTop: "0", marginBottom: "0.3em" }}>  */}
              {stateLanguage === "EN"
                  ? <button className="btn btn-outline-primary buttonStyle">More details</button>
                  : stateLanguage === "ES"
                      ? <button className="btn btn-outline-primary buttonStyle">Más detalles</button>
                      : stateLanguage === "UA"
                          ? <button className="btn btn-outline-primary buttonStyle">Детальніше</button>
                          : <button className="btn btn-outline-primary buttonStyle">Mehr Details</button>}
            </Link>
          </div>
        </div>
      </div>


      <h3 className="mini-header" id="block1">
        {stateLanguage === "EN"
            ? <React.Fragment>Game projects</React.Fragment>
            : stateLanguage === "ES"
                ? <React.Fragment>Proyectos de juegos </React.Fragment>
                : stateLanguage === "UA"
                    ? <React.Fragment>Ігрові проекти</React.Fragment>
                    : <React.Fragment>Spielprojekte </React.Fragment>}
      </h3>
      <div className="container">
        <div style={{textAlign: "center", marginTop: "0.3em", marginBottom: "0.3em"}} className="description-text">
          <React.Fragment>Coming Soon...</React.Fragment>
        </div>
      </div>
      <div>
        <div id="block2">
          <h3 className="mini-header">
            {stateLanguage === "EN"
                ? <React.Fragment>Our services</React.Fragment>
                : stateLanguage === "ES"
                    ? <React.Fragment>Nuestros servicios</React.Fragment>
                    : stateLanguage === "UA"
                        ? <React.Fragment>Наші сервіси</React.Fragment>
                        : <React.Fragment>Unsere Dienstleistungen</React.Fragment>}
          </h3>
        </div>
        <div className="col-12">
          <div className="h-100" style={{margin: '0 auto'}}>
            <div className="row pictureStyle">
              <div className="col-4">
                <img className="koza-logo1" src={arGreetingLogo} alt="AR-KOZA-PARTY LOGO"/>
              </div>
              <div className="col-4">
                <img className="koza-logo2" src={arGreetingText} alt="AR-KOZA-PARTY TEXT"/>
              </div>
              <div className="col-4">
                <a>
                  <img className="koza-logo3" src={playserviceLogo} alt="GOOGLE PLAY MARKET LOGO"/>
                  {/*<img className="koza-logo4" src={playserviceLogoMob} alt="GOOGLE PLAY MARKET LOGO" />*/}
                </a>
              </div>
            </div>
            <div className='row col-12 ' style={{margin: '0 auto'}}>
              <div className='col-2'></div>
              <img id="loppgif" src={mainGif} style={{borderRadius: "4em", backgroundRepeat: "repeat"}}/>
              {/* <img className="col-4" src={arkoza_prev1} alt="AR-KOZA-PARTY LOGO" />
                <img className="col-4" src={arkoza_prev2} alt="AR-KOZA-PARTY LOGO" /> */}
            </div>


            <div className="description-text" style={{textAlign: 'center', marginBottom: '0.1em', fontSize: "1.25em"}}>
              {stateLanguage === "EN"
                  ? <React.Fragment>The ARgreetings is the platform for communicate with friends through AR-scenes and
                    models.<br/> Take the plunge into AR with ARgreetings!</React.Fragment>
                  : stateLanguage === "ES"
                      ?
                      <React.Fragment>ARgreetings es la plataforma para comunicarse con amigos a través de AR-escenas y
                        modelos. <br/>¡Sumérjase en AR con ARgreetings! ¡Prueba o conviértete en
                        coautor!</React.Fragment>
                      : stateLanguage === "UA"
                          ? <React.Fragment>ARgreetings — платформа для спілкування з друзями через AR-сцени та
                            моделі.<br/> Пориньте в AR з ARgreetings!</React.Fragment>
                          : <React.Fragment>ARgreetings ist eine Plattform für die Kommunikation mit Freunden über
                            AR-Szenen und -Modelle. <br/>Tauchen Sie mit ARgreetings in AR ein!</React.Fragment>}
            </div>

            <div className='col-12' style={{textAlign: 'center'}}>
              <a href={"https://argreetings.aforehand.com.ua"} targer="_blank"
                 style={{marginTop: "0", marginBottom: "0.5em"}}>
                {/* <Link to={"/ar-koza-party"+((stateLanguage!=undefined) ? "/"+stateLanguage.toLowerCase(): "")} style={{ marginTop: "0", marginBottom: "0.3em" }}>  */}
                {stateLanguage === "EN"
                    ? <button className="btn btn-outline-primary buttonStyle">More details</button>
                    : stateLanguage === "ES"
                        ? <button className="btn btn-outline-primary buttonStyle">Más detalles</button>
                        : stateLanguage === "UA"
                            ? <button className="btn btn-outline-primary buttonStyle">Детальніше</button>
                            : <button className="btn btn-outline-primary buttonStyle">Mehr Details</button>}
              </a>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
</React.Fragment>;
   var isMobile = window.mobileCheck();
   console.log("Mobile:" + isMobile);
    return((!isMobile) ?
      <main className={"main" + mainClassName} style={{ paddingBottom: "0%" }}>
        {fhdContent }
      </main >
      :
      <main className={"main" + mainClassName} style={{ paddingBottom: "0%", paddingTop:'99.77px' }}>
        {mobileContent}
      </main>
    );
  }
}

export default MainPage;