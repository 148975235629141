import React from 'react'
import { Link } from 'react-router-dom';
import Language from "./Language";
export default class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLanguage: { ...props },
           
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const [languages] = Language;
        const { stateLanguage } = this.props;
        return (
            <main className="main">
                <div className="container">
                    <div className="center-elem">
                        <h1 style={{ paddingTop: "5%", color:"purple" }}>
                        {stateLanguage && languages[stateLanguage].pageNot}
                        </h1>
                        <div style={{ paddingTop: "2%" }}>
                        {stateLanguage && languages[stateLanguage].check}
                        </div>
                        <div style={{ paddingTop: "2%" }}>
                        </div>
                        <Link to={"/main"} style={{paddingTop: "2%"}}>
                        {stateLanguage && languages[stateLanguage].button}
                        </Link>
                    </div>
                </div>
            </main>
        );
    }
}